import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FiUpload, FiDownload, FiSun, FiMoon, FiImage } from 'react-icons/fi';

const SVGConverter = ({ isDarkMode, setIsDarkMode }) => {
  const [svgFile, setSvgFile] = useState(null);
  const [svgCode, setSvgCode] = useState('');
  const [outputFormat, setOutputFormat] = useState('jpeg');
  const [outputWidth, setOutputWidth] = useState(300);
  const [convertedImage, setConvertedImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [isTransparent, setIsTransparent] = useState(false);
  const [preview, setPreview] = useState(null);
  const [isConverting, setIsConverting] = useState(false);

  useEffect(() => {
    document.body.className = isDarkMode ? 'bg-black' : 'bg-white';
  }, [isDarkMode]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSvgFile(file);
    setSvgCode('');
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
      setConvertedImage(null);
    }
  };

  const handleSvgCodeChange = (e) => {
    const code = e.target.value;
    setSvgCode(code);
    setSvgFile(null);
    if (code) {
      const blob = new Blob([code], { type: 'image/svg+xml' });
      setPreview(URL.createObjectURL(blob));
    } else {
      setPreview(null);
      setConvertedImage(null);
    }
  };

  const handleConvert = async () => {
    if (!svgFile && !svgCode && !preview) return;

    setIsConverting(true);
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const scaleFactor = outputWidth / img.width;
      canvas.width = outputWidth;
      canvas.height = img.height * scaleFactor;

      if (!isTransparent) {
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const dataUrl = canvas.toDataURL(`image/${outputFormat}`);
      setConvertedImage(dataUrl);
      setIsConverting(false);

      // Automatically trigger download
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `converted.${outputFormat}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    img.src = preview || (svgCode ? `data:image/svg+xml,${encodeURIComponent(svgCode)}` : URL.createObjectURL(svgFile));
  };

  const handleLoadExample = async () => {
    try {
      const response = await fetch('/briefcase.svg');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const svgText = await response.text();
      setSvgCode(svgText);
      const blob = new Blob([svgText], { type: 'image/svg+xml' });
      setPreview(URL.createObjectURL(blob));
    } catch (error) {
      console.error('Error loading example SVG:', error);
    }
  };

  // Load example image on component mount
  useEffect(() => {
    handleLoadExample();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ type: "spring", stiffness: 50, damping: 20 }}
      className={`font-jetbrains-mono
      ${isDarkMode ? 'bg-black text-white' : 'bg-white text-black'}
      px-8 py-6 rounded-3xl w-[350px] shadow-xl flex flex-col items-start justify-between relative m-4`}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 1.5, type: "spring", stiffness: 40, damping: 15 }}
        className="absolute inset-0 z-0">
        <div className={`absolute inset-0 bg-gradient-to-r ${isDarkMode ? 'from-[#141414] to-[#000000]' : 'from-gray-200 to-gray-300'} opacity-75 rounded-3xl`}></div>
        <div className={`absolute inset-[10px] ${isDarkMode ? 'bg-black' : 'bg-white'} rounded-[calc(1.5rem-5px)]`}></div>
      </motion.div>
      
      <motion.div
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.6, ease: "easeInOut" }}
        className="w-full flex flex-col justify-between h-full z-10"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>SVG to JPEG/PNG</h2>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setIsDarkMode(!isDarkMode)}
            className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-900 text-yellow-300' : 'bg-gray-200 text-gray-700'}`}
          >
            {isDarkMode ? <FiSun /> : <FiMoon />}
          </motion.button>
        </div>

        <div className="space-y-4 mb-6">
          <div className="relative">
            <input
              type="file"
              accept=".svg"
              onChange={handleFileChange}
              className="hidden"
              id="svg-file-input"
            />
            <label
              htmlFor="svg-file-input"
              className={`flex items-center justify-center w-full px-4 py-2 border-2 border-dashed rounded-md cursor-pointer
              ${isDarkMode ? 'border-gray-600 hover:border-gray-500' : 'border-gray-300 hover:border-gray-400'}`}
            >
              <FiUpload className="mr-2" />
              <span className="text-sm">{svgFile ? svgFile.name : 'Choose SVG file'}</span>
            </label>
          </div>
          <textarea
            value={svgCode}
            onChange={handleSvgCodeChange}
            placeholder="Paste your SVG code here"
            className={`w-full h-32 p-2 border rounded-md text-sm ${
              isDarkMode ? 'bg-gray-800 text-gray-300 border-gray-700' : 'bg-white text-gray-800 border-gray-300'
            }`}
          />
          <button
            onClick={handleLoadExample}
            className={`text-sm ${isDarkMode ? 'text-blue-400' : 'text-blue-600'} hover:underline`}
          >
            Load example image
          </button>
          {preview && (
            <div className="mt-4 h-32 overflow-hidden rounded-md bg-gray-100 flex items-center justify-center">
              <img src={preview} alt="Preview" className="max-w-full max-h-full object-contain" />
            </div>
          )}
          <div className="flex space-x-4">
            <div className="flex-1">
              <label className={`block mb-2 text-xs font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Output Format
              </label>
              <div className="flex space-x-4">
                {['jpeg', 'png'].map((format) => (
                  <label key={format} className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio"
                      name="outputFormat"
                      value={format}
                      checked={outputFormat === format}
                      onChange={() => setOutputFormat(format)}
                    />
                    <span className="ml-2 text-sm capitalize">{format}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div>
            <label className={`block mb-2 text-xs font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              Background
            </label>
            <div className="flex items-center space-x-2">
              <input
                type="color"
                value={backgroundColor}
                onChange={(e) => setBackgroundColor(e.target.value)}
                className="w-6 h-6 rounded-md"
                disabled={isTransparent}
              />
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={isTransparent}
                  onChange={(e) => setIsTransparent(e.target.checked)}
                  className="form-checkbox"
                />
                <span className="ml-2 text-sm">Transparent</span>
              </label>
            </div>
          </div>
          <div>
            <label className={`block mb-2 text-xs font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              Output Width
            </label>
            <input
              type="number"
              value={outputWidth}
              onChange={(e) => setOutputWidth(e.target.value)}
              className={`block w-full px-3 py-2 border rounded-md text-sm ${
                isDarkMode ? 'bg-gray-800 text-gray-300 border-gray-700' : 'bg-white text-gray-800 border-gray-300'
              }`}
            />
          </div>
        </div>

        <button
          onClick={handleConvert}
          disabled={(!svgFile && !svgCode && !preview) || isConverting}
          className={`flex items-center justify-center px-4 py-2 rounded-md transition-colors duration-200 w-full ${
            (!svgFile && !svgCode && !preview) || isConverting
              ? 'bg-gray-400 cursor-not-allowed'
              : isDarkMode
              ? 'bg-gray-800 hover:bg-gray-700 text-white'
              : 'bg-gray-200 hover:bg-gray-300 text-black'
          }`}
        >
          {isConverting ? (
            <FiImage className="animate-spin mr-2" />
          ) : convertedImage ? (
            <FiDownload className="mr-2" />
          ) : (
            <FiImage className="mr-2" />
          )}
          <span className="text-sm">{isConverting ? 'Converting...' : convertedImage ? 'Download' : 'Convert'}</span>
        </button>
      </motion.div>
    </motion.div>
  );
};

export default SVGConverter;
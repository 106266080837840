import React, { useState } from 'react';
import { Analytics } from "@vercel/analytics/react";
import SVGConverter from './components/SVGConverter';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <div className={`App min-h-screen flex flex-col ${isDarkMode ? 'bg-black text-white' : 'bg-white text-black'}`}>
      <div className="flex-grow flex items-center justify-center">
        <SVGConverter isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
      </div>
      <footer className={`py-4 text-center text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        Inspired by <a href="https://x.com/simonw/status/1843018290312183923" target="_blank" rel="noopener noreferrer" className="underline hover:text-blue-500">this tweet</a> by <a href="https://twitter.com/simonw" target="_blank" rel="noopener noreferrer" className="underline hover:text-blue-500">Simon Willison</a>. 
        Check out his <a href="https://tools.simonwillison.net/svg-render" target="_blank" rel="noopener noreferrer" className="underline hover:text-blue-500">SVG to JPEG/PNG tool</a> as well.
        <br />
        Made by <a href="https://linkedin.com/in/prateekkeshari" target="_blank" rel="noopener noreferrer" className="underline hover:text-blue-500">Prateek Keshari</a>.
      </footer>
      <Analytics />
    </div>
  );
}

export default App;